<template>
  <div>
    <div class="section--banner">
      <h2>Nossa Família</h2>
      <p>
        Na JMJ nos consideramos uma big family. Buscamos aceitar e entender as
        pessoas como elas são, afinal cada ser é singular e isso que nos torna
        especiais. Assim, fazemos com que nossa equipe seja entrosada dentro e
        fora da empresa, seja no vôlei aos sábados ou confraternizações
        familiares!
      </p>
      <div class="bg">
        <img src="assets/img/foto-familia-site-01.png" class="circle" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Familia",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg{
  opacity: 0.5 !important;
}

@media (max-width: 900px) {
  #familia .bg img {
    max-height: 240px !important;
    max-width: 240px !important;
    margin: auto;
  }
}

@media (max-width: 640px) {
  #familia .bg img {
    max-height: 130px !important;
    max-width: 130px !important;
  }
}
</style>
