<template>
  <header class="header">
    <div class="header--nav-toggle">
      <span></span>
    </div>
    <router-link :to="{ name: 'Home' }" class="header--logo" style="pointer-events: all; cursor: pointer">
      <img src="/assets/img/logo 64.svg" alt="JMJ Sistemas e Consultoria" />
    </router-link>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>
<style scoped>
.header--nav-toggle {
  margin-left: -20px;
}
@media (min-width: 991px) {
  .header--logo img {
    height: 38px;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 70px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 10;
  padding-top: 20px;
}

.header--logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.header--logo img {
  height: 24px;
}

.header--logo p {
  margin: 0 0 0 10px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.header--nav-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.header--nav-toggle span,
.header--nav-toggle::before,
.header--nav-toggle::after {
  content: "";
  position: relative;
  width: 16px;
  height: 2px;
  background-color: var(--cor-secundaria);
}

.header--nav-toggle::before {
  bottom: 5px;
  width: 23px;
}

.header--nav-toggle::after {
  top: 5px;
  width: 23px;
}

@media (max-width: 768px) {
  .header--logo {
    margin-left: -21px;
  }
}
</style>
