<template>
  <!--FOOTER-->
  <footer class="footerPortal">
    <div class="contatos">
      <h4>Contatos: </h4>
      <div class="div-contatos">
        <div class="endereco">
          <Icon icon="iconMap" />
          <p>Av.Bruno Martini,
            926 - Jd. Barcelona
            Sinop, Mato Grosso, Brasil
          </p>
        </div>
        <div class="funcionamento">
          <Icon icon="iconTelephone" />
          <div>
            <p>(66) 3532-5331</p>
            <p>Funcionamento: segunda a sexta de 07h as 17h.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="redes-sociais">
      <h4>Siga nossas redes sociais:</h4>
      <div class="div-redes-sociais">
        <div>
          <a
              href="https://wa.me/556635325331"
              title="Enviar mensagem via Whatsapp"
              target="_blank"
              rel="noopener"
          >
            <img src="assets/img/icones/whatsapp.png" alt="Whatsapp"/>
          </a>
        </div>
        <div>
          <a
              href="https://www.instagram.com/jmjsistemas/"
              title="Rede Social: Instagram"
              target="_blank"
              rel="noopener"
          >
            <img src="assets/img/icones/instagram.png" alt="Instagram"/>
          </a>
        </div>
        <div>
          <a
              href="https://www.linkedin.com/company/jmj-sistemas"
              title="Rede Social: LinkedIn"
              target="_blank"
              rel="noopener"
          >
            <img src="assets/img/icones/linkedin.png" alt="LinkedIn"/>
          </a>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  name: "footerJmj"
}
</script>

<style scoped>

.contatos {
  width: 50%;
}

.contatos .div-contatos {
  display: flex;
  flex-direction: row;
  width: 100%;
}

 .endereco,  .funcionamento {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 20px;
}
 .endereco p,  .funcionamento p{
  max-width: 80%;
  margin: 0 20px;
  font-size: medium;
}
 .contatos h4,  .redes-sociais h4{
   font-weight: 700;
   font-size: x-large;
   line-height: 10%;
   margin-bottom: 35px;
}

.footerPortal {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: baseline;
  -webkit-box-pack: justify;
  -webkit-justify-content: center;
  padding: 1% 1%;
  width: 100%;
  margin: auto;
}

.redes-sociais .div-redes-sociais {
  display: flex;
  flex-direction: row;
  gap: 10%;
}

@media (max-width: 768px) {
  .contatos .div-contatos {
    display: block;
  }

  .endereco,  .funcionamento {
    margin-bottom: 5%;
  }
}

@media (max-width: 629px) {
  .footerPortal {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }

  .contatos, .redes-sociais {
    margin: auto;
  }

  .div-redes-sociais {
    justify-content: center;
  }
}

@media (max-width: 424px) {
  .contatos {
    width: 100%;
  }

  .endereco,  .funcionamento {
    margin-right: 0;
  }

  .endereco p,  .funcionamento p{
    max-width: 100%;
  }
}

@media (max-width: 314px) {
  .redes-sociais h4{
    line-height: 100%;
  }
}

</style>