import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/HomeView.vue'),
        props:{
          showMenu: true,
        }
    },
    {
        path: '/suemg',
        name: 'SUEMG',
        component: () => import('@/pages/ContatoView.vue'),
        props: { showMenu: false, origem: 'SUEMG' },
    },
    {
      path: '/porlgpd',
      name: 'PORLGPD',
      component: () => import('@/pages/ViewLgpd.vue'),
      props: { showMenu: false, origem: 'PORLGPD' },
    }
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to) {
      const scroll = {}
      if (to.meta.toTop) scroll.top = 0
      if (to.meta.smoothScroll) scroll.behavior = 'smooth'
      return scroll
    }
  })

export default router