<template>
  <div class="cookies">
    <div class="texto-cookie">
      <Icon icon="securityShield" />
      <p class="cookiestext">
        A JMJ Sistemas e Consultorias se preocupa com o correto tratamento dos dados pessoais. Conheça nosso portal de privacidade
      </p>
    </div>
    <div class="botoes-cookie">
      <router-link to="/porlgpd">
        <button type="button" class="contact-button" @click=""> Saiba Mais </button>
      </router-link>
      <button class="cookies-close-button" @click="closeCookies">
        <Icon icon="closeBlack" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
export default {
  components: { Icon },
  name: "CookieLgpd",
  methods:{
    closeCookies: function(){
      {
        $('.cookies-close-button').click(function(){
        $('.cookies').fadeOut(500);});
      }
    }
  },
};

</script>
<style scoped>

.cookies{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 1px 0px;
  background-color: var(--cor-azul);
  color: #fff;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body{
  margin:0;
  font-family:var(--font-family);
}

.texto-cookie{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

.botoes-cookie{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.contact-button {
  color: var(--cor-primaria-20);
  border: 1px solid var(--cor-primaria-20);
  padding: 4px 20px;
  background: var(--cor-azul);
  border-radius: 30px;
  height: fit-content;
  width: fit-content;
  font-size: 17px;
  font-weight: 500;
  line-height: 118%;
  margin: 15px;
}

.contact-button:hover {
  font-weight: 700;
  background: var(--cor-primaria-60);
  border-color: var(--cor-primaria-60);
  color: var(--cor-preto);
}

.cookiestext {
  color:white;
  padding-left:10px;
  padding-right:10px;
  text-align:justify;
}

.cookies-close-button {
  float:right;
  margin:15px;
  background:none;
  padding-top: 5px;
  border: none;
}

.cookies-close-button:hover {
  border: white;
  border-radius: 30px;
  background: none;
  color: transparent;
}

@media (max-width: 425px){
  .cookies {
    display: block;
  }
  .cookies .texto-cookie {
    max-width: 90%;
  }

  .cookies .botoes-cookie {
    justify-content: end;
    margin-top: -15px;
  }

}

@media (max-width: 768px){
  .cookies {
    justify-content: flex-start;
  }
  .texto-cookie {
    max-width: 60%;
  }

}
</style>
