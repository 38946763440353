<template>
  <Modal
    :show="show"
    @modalClosed="modalOnClose"
    :onSubmit="submitForm"
    class="no-border"
  >
    <!-- checkout formspree.io for easy form setup -->
    <form id="contactForm" class="work-request" v-on:submit.prevent>
      <div class="form-overlay" v-show="sending">
        <h4>Aguarde, estamos enviando sua mensagem.</h4>
      </div>
      <div class="work-request--information">
        <label for="name">Nome</label>
        <input
          id="name"
          name="name"
          required="true"
          autocomplete="off"
          type="text"
          spellcheck="false"
          v-model="messageForm.name"
        />
      </div>
      <div class="work-request--information">
        <label for="email">E-mail Profissional</label>
        <input
          id="email"
          name="email"
          required="true"
          autocomplete="off"
          type="email"
          spellcheck="false"
          v-model="messageForm.email"
        />
      </div>
      <div class="work-request--information" v-if="!contactOnly">
        <label for="telefone">Telefone</label>
        <input
          id="phone"
          name="phone"
          required="true"
          autocomplete="off"
          type="text"
          spellcheck="false"
          v-model="messageForm.phone"
        />
      </div>
      <div class="work-request--information">
        <label for="ass">{{
          contactOnly
            ? "Assunto"
            : "Deseja saber sobre algum sistema em específico?"
        }}</label>
        <input
          id="ass"
          name="ass"
          required="true"
          autocomplete="off"
          type="text"
          v-model="messageForm.ass"
        />
      </div>
      <div class="work-request--information" v-if="!contactOnly">
        <label for="company">Deseja marcar uma reunião?</label>

        <div>
          <label class="radio-container"
            >Sim
            <input
              type="radio"
              required
              name="meeting"
              v-model="messageForm.meeting"
              value="1"
            />
            <span class="checkmark"></span>
          </label>
          <label class="radio-container"
            >Não
            <input
              type="radio"
              required
              name="meeting"
              v-model="messageForm.meeting"
              value="0"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="work-request--information" v-if="!contactOnly">
        <label for="company">Empresa</label>
        <input
          id="company"
          name="company"
          required="true"
          autocomplete="off"
          type="text"
          spellcheck="false"
          v-model="messageForm.company"
        />
      </div>
      <div class="work-request--information" v-if="!contactOnly">
        <label for="posi">Cargo</label>
        <input
          id="posi"
          name="posi"
          required="true"
          autocomplete="off"
          type="text"
          spellcheck="false"
          v-model="messageForm.posi"
        />
      </div>

      <div v-if="contactOnly" class="work-request--information">
        <label for="msg">Mensagem</label>
        <textarea
          id="msg"
          name="msg"
          required="true"
          v-model="messageForm.msg"
          v-bind:class="{ 'has-value': messageForm.msg }"
        ></textarea>
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import { messages } from "../services/firebase.js";
import CustomForm from "@schirrel/custom-form/CustomForm.js";
export default {
  name: "ModalContact",
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalClosed: {
      type: Boolean,
      default: false
    },
    contactOnly: {
      type: Boolean,
      default: false
    },
    origem: {
      type: String,
      default: 'Não informado'
    }
  },
  data: function () {
    return {
      sending: false,
      messageForm: {},
      emailValidation: {
        name: "email",
        validation: (value) => {
          var emailRegex = /\S+@\S+\.\S+/;
          return !emailRegex.test(value);
        },
        validationMessage: "E-mail inválido",
      },
      phoneValidation: {
        name: "phone",
        validation: (value) => {
          var emailRegex = /\d+/;
          return !emailRegex.test(value);
        },
        validationMessage: "Deve conter somente números",
      },
      contactCustomForm: {
        fields: ["name", "email", "ass", "msg"],
        requires: ["name", "email", "ass", "msg"],
        customValidations: [this.emailValidation],
      },
      completeCustomForm: {
        fields: ["name", "email", "phone", "ass", "meeting", "company", "posi"],
        requires: ["name", "email", "phone", "ass", "meeting"],
        customValidations: [this.emailValidation, this.phoneValidation],
      },
    };
  },
  created() {
    this.sendMessage.bind(this);
    this.contactCustomForm.customValidations = [this.emailValidation];
    this.completeCustomForm.customValidations = [
      this.emailValidation,
      this.phoneValidation,
    ];
    this.messageForm = this.contactOnly
      ? new CustomForm(this.contactCustomForm, this.sendMessage)
      : new CustomForm(this.completeCustomForm, this.sendMessage);
  },
  methods: {
    submitForm() {
      this.messageForm.callSubmit();
    },
    prepareToSend(message) {
      message.quando = new Date().toString();
      message.origem = this.origem;

      if (!this.contactOnly) {
        message.company = message.company || "";
        message.posi = message.posi || "";
      }
      return message;
    },

    sendMessage: function (message) {
      this.sending = true;
      message = this.prepareToSend(message);
      messages
        .save(message)
        .then(() => {
          this.sending = false;
          this.$emit("modalSuccess");
        })
        .catch((err) => {
          this.sending = false;
          alert("Aconteceu algum erro :/");
          console.log(err);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    closeModal: function () {
      this.sending = false;
      this.form.reset();
    },
    modalOnClose() {
      this.$emit("modalClosed");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.work-request {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.work-request input[type="submit"] {
  width: 400px;
  max-width: 100%;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--cor-secundaria);
  border: none;
  border-radius: 4px;
}

.work-request input[type="submit"]:focus {
  outline: none;
}

.work-request--options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 30px 0;
}

.work-request--options .options-a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.work-request--options .options-b {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 72%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.work-request--options label {
  display: block;
  width: 200px;
  margin-bottom: 30px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border: 2px solid #fff;
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.work-request--options label svg {
  position: relative;
  left: -5px;
  width: 0;
  fill: #fff;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.work-request--options input[type="checkbox"] {
  display: none;
}

.work-request--options input[type="checkbox"]:checked + label {
  background-color: var(--cor-primaria);
  border-color: var(--cor-primaria);
}

.work-request--options input[type="checkbox"]:checked + label svg {
  width: 15px;
}

.work-request--information {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  flex-direction: column;
}

.work-request--information .information-name,
.work-request--information .information-email {
  position: relative;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 300;
}

.work-request--information .information-assunto {
  position: relative;
  width: 100% !important;
  height: 50px;
  font-size: 14px;
  font-weight: 300;
}

.work-request--information .information-descricao {
  position: relative;
  width: 100% !important;
  height: 100px;
  font-size: 14px;
  font-weight: 300;
}

.information-descricao textarea {
  height: 80px;
}

.work-request > div.form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000ab;
  z-index: 9;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.form-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.work-request--information input[type="text"],
.work-request--information input[type="email"],
.work-request--information textarea {
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
  border: 1px solid var(--cor-secundaria);
  border-radius: 4px;
}

.work-request--information input[type="text"]:focus,
.work-request--information input[type="email"]:focus,
.work-request--information textarea:focus {
  outline: none;
  background-color: var(--cor-preto);
}

.work-request--information label {
  color: var(--cor-primaria-60);
}

.work-request--information input:focus + label,
.work-request--information input.has-value + label,
.work-request--information textarea:focus + label,
.work-request--information textarea.has-value + label {
  top: -15px;
  font-size: 14px;
}

@media (max-width: 769px) {
  .work-request--options {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .work-request--options .options-a,
  .work-request--options .options-b {
    display: block;
    width: auto;
  }
}

@media (max-width: 600px) {
  .work-request--options {
    margin: 20px 0;
  }
}

@media (max-width: 600px) and (max-width: 415px) {
  .work-request--options {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 600px) {
  .work-request--options label {
    width: 150px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .work-request--options input[type="checkbox"]:checked + label svg {
    width: 12px;
  }

  .work-request--information {
    margin-bottom: 5px;
  }

  .work-request--information .information-name,
  .work-request--information .information-email,
  .work-request--information .information-assunto {
    height: 40px;
    font-size: 14px;
  }
}

@media (max-width: 769px) {
  input,
  .work-request--information .information-name,
  .work-request--information .information-email,
  .work-request--information .information-assunto {
    min-height: 20px;
    padding: 0px;
    margin-bottom: 0px;
  }

  .work-request--information.information-name,
  .work-request--information .information-email {
    display: block;
    width: 100%;
  }

  .information-descricao textarea,
  .work-request--information .information-descricao {
    height: 150px;
  }

  .work-request--information {
    margin-bottom: 5px;
  }
}
.radio-container {
  display: inline;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 22px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: var(--cor-primaria);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
 	    top: 5px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}
</style>
