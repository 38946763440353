<template>
  <div class="about">
    <div class="section--banner">
      <h3>Quem está conosco fazendo a diferença</h3>
      <h2>Nossos Parceiros</h2>
      <div class="row parceiros-list">
        <div class="col-12" :key="index" v-for="(parceiro, index) in parceiros">
          <a target="_blank" :href="parceiro.url" rel="noopener">
            <img :src="parceiro.logo" :alt="parceiro.nome" />
            <h5>{{ parceiro.nome }}</h5>
          </a>
        </div>
      </div>
    </div>
    <div class="bg parceiros-bg-graphics">
      <div
        class="lottie-animation"
        data-src="assets/lottie/shaking-hands/data.json"
      ></div>
    </div>
  </div>
</template>

<script>
import { partners } from "@/services/firebase.js";
export default {
  name: "Parceiros",
  data() {
    return {
      selectedIndex: 0,
      modified: 0,
      intercepted: false,
      parceiros: [
        //     {
        //     nome: 'DRG Brasil ®',
        //     imagem: 'assets/img/parceiros/drg.png',
        //     site: 'https://www.drgbrasil.com.br/'
        // },
        {
          nome: "Transforma Saúde",
          imagem: "assets/img/parceiros/transforma-saude.webp",
          site: "https://www.linkedin.com/company/transforma-sa%C3%BAde/",
        },
        {
          nome: "Milena Cunha",
          imagem: "assets/img/parceiros/milena-cunha.webp",
          site: "http://milenacunha.com.br/home",
        },
        {
          nome: "Result",
          imagem: "assets/img/parceiros/result.webp",
          site: "#",
        },
      ],
    };
  },
  mounted() {
    partners.list().then((res) => {
      this.parceiros = res;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
.row.parceiros-list h5 {
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 700;
}
.bg.parceiros-bg-graphics img {
  height: 100%;
}

.parceiros-wrapper {
  width: 700px;
  margin-top: 10px;
  text-align: center;
}
.row.parceiros-list {
padding-top:30px
}
.row.parceiros-list,
.row.parceiros-list > div {
  margin-left: 0px !important; 
  padding-left: 0px !important; 
}


.row.parceiros-list a {
  position: relative;
   text-decoration: none;
}

.row.parceiros-list img {
  position: relative;
  max-height: 50px;
}

.parceiros-wrapper {
  padding-top: 20px;
}

@media (max-width: 769px) {
  .row.parceiros-list h5 {
    display: none;
  }
}

@media (max-width: 580px) {
  .parceiros-wrapper {
    margin-top: 5px;
    width: auto;
    text-align: center;
  }
}
</style>
