import config from "@/config.js";

const $db = firebase.initializeApp(config);
firebase.messaging();

const snapshotToArray = function(snapshot) {
  var returnArr = [];

  snapshot.forEach(function(childSnapshot) {
    var item = childSnapshot.val();
    item.key = childSnapshot.key;

    returnArr.push(item);
  });

  return returnArr;
};
const actions = (ref) => {
  return {
    save: (model) => {
      let db = $db.database();
      if (model.key) {
        return db
          .ref(ref)
          .child(model.key)
          .update(model);
      } else {
        return db
          .ref()
          .child(ref)
          .push(model);
      }
    },
    list: () => {
      let db = $db.database();
      return db
        .ref(ref)
        .once("value")
        .then((res) => snapshotToArray(res));
    },
  };
};

export const messages = actions("api/messages");
export const clients = actions("api/clients");
export const products = actions("api/products");
export const partners = actions("api/partners");
export const positions = actions("api/positions");
