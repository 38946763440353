<template>
  <div class="trabalhe-conosco">
    <div class="section--banner">
      <h3>Trabalhe conosco</h3>
      <h2>Entre para a Família JMJ</h2>
      <div
        class="lottie-animation"
        data-src="assets/lottie/lampada/data.json"
      ></div>

      <div class="section-content">
        <div class="row">
          <div class="col-6">
            <h4 class="column-title" @click="showNextColumnBody">
              . Como trabalhamos ?
            </h4>
            <div class="column-content">
              <p>
                Aqui na JMJ nós trabalhamos com softwares voltados para a área
                da saúde e temos como foco melhorar os processos das operadoras
                de saúde. Para atingirmos esses objetivos, somos empenhados em
                seguir alguns valores e princípios com a segurança da
                informação, integridade dos dados e entrega com melhoria
                contínua. E para atingirmos isso, somos ligados em:
              </p>
              <ol class="requires-list">
                <li>
                  <Icon icon="user" />
                  <span>Experiência do Usuário</span>
                </li>
                <li>
                  <Icon icon="idea" />
                  <span>Design Sprint e Design Thinking</span>
                </li>
                <li>
                  <Icon icon="stopwatch" />
                  <span>Desenvolvimento Ágil</span>
                </li>
                <li>
                  <Icon icon="settings" />
                  <span
                    >Metodologia de Gestão de Processos de Negócios (BPM)</span
                  >
                </li>
              </ol>
            </div>
          </div>
          <div class="col-6">
            <h4 class="column-title" @click="showNextColumnBody">
              . Sabe o que estamos buscando ?
            </h4>
            <div class="column-content">
              <p>
                Integrantes para o time que tenham um pensamento proativo e
                inovador. Aqui nós prezamos pelo respeito, pela colaboração e
                pelo amor em criar soluções!!! Temos ainda perfis abrangentes de
                todas as áreas, então muitas vezes não precisamos aguardar
                nossos colegas ficarem disponíveis para seguirmos com nossas
                atividades.
              </p>
              <ol class="requires-list">
                <li>
                  <Icon icon="like" />
                  <span> Amor pelo que faz </span>
                </li>
                <li>
                  <Icon icon="cogs" />
                  <span> Paixão por resolver problemas</span>
                </li>
                <li>
                  <Icon icon="idea" />
                  <span> Inquietude e Criatividade</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div
          class="lottie-animation"
          id="clicker-animation"
          data-src="assets/lottie/trabalhe-conosco/data.json"
        ></div>
        <ViewOpenPosition @fabCLicked="toggleModal" />
        <ModalPositions :slide="showModal" @onClose="toggleModal" />
      </div>
    </div>
  </div>
</template>

<script>
import ModalPositions from "../ModalPositions.vue";
import ViewOpenPosition from "../ViewOpenPosition.vue";
import Icon from "../Icon.vue";
export default {
  components: { ViewOpenPosition, ModalPositions, Icon },
  name: "TrabalheConosco",
  data: function () {
    return {
      showModal: false,
    };
  },
  methods: {
    showNextColumnBody($event) {
      let nextContent = $event.target.nextElementSibling;
      Array.from(document.querySelectorAll(".trabalhe-conosco .column-content"))
        .filter((content) => content != nextContent)
        .forEach((content) => {
          content.classList.remove("show");
        });
      $event.target.nextElementSibling.classList.toggle("show");
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.trabalhe-conosco h4 {
  color: var(--cor-secundaria);
  font-weight: 500;
  font-size: 18px;
}

.trabalhe-conosco ol {
  list-style: none;
}

.trabalhe-conosco .section-content {
  padding-top: 30px;
}

.requires-list,
.requires-list li {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
.trabalhe-conosco .requires-list li {
  color: var(--cor-primaria-60);
  display: flex;
  vertical-align: middle;
  align-content: center;
  margin: 6px 0;
  justify-content: initial;
}

.trabalhe-conosco li span {
  line-height: 24px;
  padding-left: 10px;
  font-size: 14px;
}
.trabalhe-conosco .lottie-animation {
  position: absolute;
  height: 400px;
  right: -56px;
  top: -250px;
  z-index: -1;
}

@media (max-width: 900px) {
  .trabalhe-conosco h4 {
    margin: 0;
  }

  .trabalhe-conosco p {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  
  .trabalhe-conosco .section-content {
    padding: 0;
  }
}
@media (max-width: 768px) {
.jmj-icon {
    display: none;
  }
  .column-title {
    cursor: pointer;
  }
  .column-content {
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: height 300ms ease;
  }

  .column-content.show {
    height: 450px;
  }
  .open-vagas {
    bottom: 100px;
  }

  #clicker-animation {
    bottom: 102px !important;
  }
}

#clicker-animation {
  right: -20px !important;
  position: fixed;
  bottom: 82px;
  top: inherit;
}
</style>
