<template>
  <div class="alert" id="alert-mensagem">
    <div class="alert-body">
      <button class="close" @click="close">x</button>
      <h4 v-if="!contactOnly">Você acabou de demonstrar interesse em nossos sistemas</h4>
      <h3>
        <strong>EM BREVE</strong> um de nossos colaboradores entrará em contato
        <span v-if="!contactOnly">para analisarmos a melhor solução para sua empresa</span>!
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertSuccess",
  props:['contactOnly'],
  methods: {
    close: function () {
      this.$emit("alertCLose");
    },
  },
};
</script>

<style scoped>

#alert-mensagem.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
    background: var(--cor-preto);
   
}

#alert-mensagem.alert .close {
  position: relative !important;
  top: inherit !important;
  left: 87% !important;
  margin: 5px !important;
  right: 0 !important;
  font-weight: 900 !important;
  font-size: 18px !important;
  padding: 0px !important;
      --size: 35px;
    display: block;

    width: var(--size);
    height: var(--size);

    cursor: pointer;
    pointer-events: auto;
    color:  #05f2f2;
    border: 2px solid #05f2f2;
    box-sizing: border-box;
    background: var(--cor-preto);
    border-radius: 40px;
}

#alert-mensagem .alert-body {
  border-radius: 20px;
  max-width: 350px !important;
  width: auto !important;
  border-color: var(--cor-secundaria-20);
  text-transform: initial;
  padding: 15px;
   height: 100%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    background: #000000;
    border: 1px solid #05f2f2;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(5 242 242 / 8%);
    z-index: 3;
}

#alert-mensagem.alert h4 {
  color: var(--cor-secundaria) !important;
  text-transform: none !important;
}

#alert-mensagem.alert h3 {
  text-transform: none !important;
  color: #f3f3f3 !important;
}

#alert-mensagem.alert strong {
  color: var(--cor-primaria) !important;
}
@media (max-width: 769px) {
  #alert-mensagem.alert {
    width: 80%;
  }
  #alert-mensagem.alert h4 {
    margin: 0;
  }
}
</style>
