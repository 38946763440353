<template>
  <div class="fab">
    <div class="FAB">
      <div class="FAB__action-button" @click="showAlertSolucoes">
        <i class="action-button__icon"
          ><img src="/assets/img/icones/question-mark.png"
        /></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FabSolutions",
  props: ["target"],
  methods: {
    showAlertSolucoes: function () {
     this.$emit('clicked')
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.FAB {
  position: absolute;
  bottom: 8px;
  right: -6px;
  width: 60px;
  height: 60px;
}
.FAB__action-button,
.FAB__mini-action-button {
  cursor: pointer;
  position: absolute;
}
.FAB__action-button {
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
}
.FAB__action-button:hover .action-button__icon {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.14), 0 8px 16px rgba(0, 0, 0, 0.28);
}

.action-button__icon {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  background-color: black;
  border-radius: 50%;
  color: var(--cor-secundaria);
  border: 2px solid;
  padding: 16px;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
}

.action-button__icon img {
  position: relative;
  width: 20px;
}

@media (max-width: 769px) {
  .action-button__icon {
    padding: 10px;
    width: 25px;
    height: 25px;
  }
  .action-button__icon img {
    width: 15px;
  }
  .FAB {
    top: 170px;
    right: -31px;
  }
}
</style>
