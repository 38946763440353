<template>
  <div>
    <div class="portal">
<!--HEADER PORTAL LGPD-->
      <section class="headerPortal">
        <img src="/assets/img/logo 64.svg" alt="JMJ Sistemas e Consultoria" class="imglogo"/>
        <div style="padding-right: 7%;">
          <router-link to="/">
            <button type="button" class="home-button" @click="">
              <span class="text-home">Voltar para site</span>
              <span class="seta-home"><Icon icon="setaHome"/></span>
            </button>
          </router-link>
        </div>
      </section>

<!--SESSÃO TÍTULO PORTAL-->
      <section class="section-titulo">
        <div class="divtitulo">
          <h2 class="tagjmj">JMJ SISTEMAS</h2>
          <h1 class="tituloportal">PORTAL DA LEI GERAL DE PROTEÇÃO DE DADOS</h1>
          <p>A JMJ Sistemas e Consultoria se preocupa com o correto tratamento dos dados pessoais,
            bem como a devida proteção e cuidado desses dados. As informações aqui contidas visam lhe auxiliar no
            entendimento do tratamento de dados pessoais pela JMJ Sistemas e Consultorias.
          </p>
        </div>
        <div>
          <img class="imgportal" src="assets/img/LGPD.svg"/>
        </div>
      </section>
      <section>
        <div class="line">
          <img src="assets/img/line-gradiente.png"/>
        </div>
      </section>

<!--SESSÃO DEFINIÇÃO-->
      <section class="definicao" >
        <p  class="question" >?</p>
        <h3>O QUE É A LGPD</h3>
        <p>A Lei geral de Proteção de Dados - LGPD (Lei 13.709/2018) é a Lei que dispõe sobre o tratamento de dados pessoais,
          inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado,
          com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre
          desenvolvimento da personalidade da pessoa natural (art. 1º)
        </p>
      </section>

<!--SESSÃO DIREITOS DO TITULAR-->
      <section class="direitos">
        <div class="subtitulo">
          <div class="titulodireitos">
            <h4 class="fonte-verde" >quais são os</h4>
            <h3>DIREITOS DO TITULAR?</h3>
          </div>
          <div class="paragrafo-direitos">
            <p>Os Titulares (pessoa natural a quem se referem os dados pessoais que são objeto de tratamento)
              possuem certos direitos assegurados pela LGPD, previsto no art. 18 da LGPG:
            </p>
          </div>
        </div>
        <!--      GRID-->
        <div class="bordaTabela">
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">01</h4>
            <div style="width: 70%;">
              <h5>Confirmação</h5>
              <p>da existência de tratamento;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">02</h4>
            <div style="width: 70%;">
              <h5>Acesso</h5>
              <p>aos dados;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">03</h4>
            <div style="width: 70%;">
              <h5>Correção</h5>
              <p>de dados incompletos, inexatos ou desatualizados;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">04</h4>
            <div style="width: 70%;">
              <h5>Anonimização,</h5>
              <p>bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o
                disposto nesta Lei;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">05</h4>
            <div style="width: 70%;">
              <h5>Portabilidade</h5>
              <p>dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa,
                de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">06</h4>
            <div style="width: 70%;">
              <h5>Eliminação</h5>
              <p>dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 desta Lei;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">07</h4>
            <div style="width: 70%;">
              <h5>Informação</h5>
              <p>das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">08</h4>
            <div style="width: 70%;">
              <h5>Informação</h5>
              <p>sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</p>
            </div>
          </div>
          <div class="cardtable" style="display: flex; -webkit-justify-content: space-between;align-items: center">
            <h4 class="fonte-verde">09</h4>
            <div style="width: 70%;">
              <h5>Revogação</h5>
              <p>do consentimento.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="exercer">
        <div class="subtitulo1">
          <div class="titulodireitos">
            <h4 class="fonte-verde" >como exercer</h4>
            <h3>SEUS DIREITOS</h3>
          </div>
          <div class="exercer-card">
            <div class="single-card">
              <h3>Direitos referentes a JMJ Sistemas</h3>
              <p>Caso possua vínculo com a JMJ Sistemas e Consultorias e queira solicitar o exercício dos dados,
                encaminhe um e-mail para nosso Encarregado.</p>
            </div>
            <div class="single-card">
              <h3>Direitos referentes a clientes e parceiros JMJ</h3>
              <p>Caso você seja um cliente ou possua vinculo contratual com qualquer um de nossos clientes e parceiros,
                solicitamos que mantenha contato diretamente com estes, pois segundo o art. 18 da LGPD tais informações
                devem ser obtidas diretamente pelo controlador.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="protecao" >
        <h4  class="fonte-verde" >encarregado pela</h4>
        <h3>PROTEÇÃO DE DADOS PESSOAIS</h3>
        <p>Em caso de eventuais dúvidas relacionada aos Dados pessoais e seu tratamento, entre em contato com o
          encarregado pela proteção de dados da JMJ, Vinicius Heming de Oliveira, através do e-mail: dpo@jmjsistemas.com.br
        </p>
        <div>
          <a href="mailto:dpo@jmjsistemas.com.br">
            <button type="button" class="contact-button">Encaminhar e-mail  para responsável</button>
          </a>
        </div>
      </section>
      <div class="borda-linda"></div>
      <FooterJmj />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon.vue";
import FooterJmj from "@/components/FooterJmj.vue";

export default {
  name: "PortalLgpd",
  components: {Icon, FooterJmj},
  props: {
    msg: String,
    showMenu: {
      type: Boolean,
      default: true
    },
    origem: {
      type: String,
      default: 'Não informado'
    },
  },
  mounted () {
    if (!this.showMenu) {
      document.querySelector('.header--nav-toggle').style.display='none'
      document.querySelector('header').style.display='none'
      document.querySelector('.l-side-nav').style.display='none'
      document.querySelector('.cookies').style.display='none'
      document.querySelector('.l-viewport').style.overflow='auto'
      document.querySelector('.l-viewport').style.background='linear-gradient(178.08deg, rgb(0 78 78 / 48%) -62.05%, rgba(0, 78, 78, 0) 94.23%)'
      document.querySelector('.l-viewport').style.touchAction='auto'
    } else {
      document.querySelector('.header--nav-toggle').style.display=''
      document.querySelector('.l-side-nav').style.display=''
      document.querySelector('header').style.display='block'
      document.querySelector('l-viewport').style.overflow='hidden'
      document.querySelector('body').style.overflow='hidden'
      document.querySelector('.l-viewport').style.background=''
      document.querySelector('.l-viewport').style.touchAction='none'

    }
  }
}
</script>

<style>

.portal .section-titulo {
  display: flex;
  padding-left: 7%;

}

@media (max-width: 2560px) {
  .portal .imgportal {
    right: 10%;
  }
}

@media (max-width: 1440px){
  .portal .imgportal {
    right: 4%;
  }

  .portal .titulodireitos{
    width: -webkit-fill-available;
  }
}

@media (max-width: 1100px) {

  .portal .titulodireitos{
    width: -webkit-fill-available;
  }

  .portal .subtitulo {
    padding: 0px 8%;
  }
}

@media (max-width: 1024px) {
  .portal .imgportal {
    right: 0;
    height: 580px;
    left: 42%;
  }
}

@media (max-width: 768px) {

  .portal .subtitulo, .portal .subtitulo1 {
    padding: 0%;
  }

  .portal section.definicao, .portal section.direitos, .portal section.exercer, .portal section.protecao {
    margin-bottom: 10%;
  }

  .portal .tagjmj {
    font-size: x-large;
    margin-bottom: -5px;
  }

  .portal .tituloportal {
    width: 40%;
    font-size: x-large;
  }

  .portal .imgportal {
    left: 45%;
    top: 86px;
    height: 400px;

  }

  .portal .line {
    margin-top: -45px;
    height: 155px;
  }

  .portal .definicao h3 {
    text-align: center;
    margin: 5px 0px 5px;
    font-size: 240%;
  }

  .portal .definicao p {
    width: 79%;
    font-size: medium;
  }

  .portal .definicao .question {
    font-size: 67px;
  }

  .portal .subtitulo {
    width: 100%;
  }

  .portal .titulodireitos {
    width: 50%;
  }

  .portal .subtitulo .titulodireitos .fonte-verde {
    font-size: xx-large;
  }

  .portal .subtitulo .titulodireitos h3 {
    font-size: 250%;
  }

  .portal .subtitulo .paragrafo-direitos{
    width: 50%;
  }

  .portal .subtitulo .paragrafo-direitos p {
    font-size: medium;
    width: 90%;
    margin-left: 0;
  }

  .portal .divtitulo p {
    max-width: 45%;
    font-size: medium;
  }

  .portal .bordaTabela {
    width: 100%;
  }

  .portal .bordaTabela .cardtable {
    width: calc((100% / 3) - 10px);
  }

  .portal .bordaTabela .cardtable p {
    font-size: small;
  }

  .portal .bordaTabela .cardtable h4 {
    font-size: 230%;
  }

  .portal .bordaTabela .cardtable h5 {
    font-size: medium;
  }

  .portal .subtitulo1 .titulodireitos h3 {
    font-size: 250%;
  }

  .portal .subtitulo1 .titulodireitos {
    width: 100%;
  }

  .portal .subtitulo, .portal .subtitulo1 {
    padding: 0%;
  }

  .portal .single-card {
    padding: 32px 8px;
  }

  .portal .single-card h3 {
    font-size: x-large;
  }

  .portal .single-card p {
    font-size: medium;
  }

  .portal .protecao h4 {
    font-size: xx-large;
  }

  .portal .protecao h3 {
    font-size: 200%;
  }

  .portal .protecao p {
    width: 80%;
    font-size: medium;
  }

  .portal .protecao .contact-button {
    padding: 8px 13px;
    font-size: larger;
  }


}

@media (max-width: 685px){
  .portal .bordaTabela .cardtable {
    width: calc((100% / 2) - 10px);
  }
}

@media (max-width: 540px) {
  .portal .imgportal {
  height: 320px;
  left: 40%;
  top: 175px;
  }

  .portal .subtitulo .titulodireitos {
    width: 100%;
  }

  .portal .subtitulo .paragrafo-direitos{
    width: 100%;
  }

  .portal .subtitulo .paragrafo-direitos p {
    width: 100%;
  }

  .portal .subtitulo{
    display: inline;
  }

  .portal .subtitulo p{
    margin: auto;
  }

  .portal .subtitulo h3{
    margin-bottom: 5px;
    width: fit-content;
  }
}

@media (max-width: 470px){
  .portal .bordaTabela .cardtable {
    width: 100%;
  }

  .headerPortal .home-button {
    content: "Site";
  }
}

@media (max-width: 425px) {

  .portal .home-button {
    border-radius: 41px;
    height: 52px;
    padding: 8px 15px;
    justify-items: center;
    width: 54px;
  }

  .portal .home-button .text-home {
    display: none;
  }

  .portal .home-button .seta-home {
    display: block;
    height: 24px;
  }
  .portal .tagjmj {
    width: 100%
  }

  .portal .tituloportal {
    width: 100%;
    font-size: xx-large;
  }

  .portal .divtitulo p {
    max-width: 100%;
  }

  .portal .imgportal {
    display: none;
  }

  .portal .line {
    display: none;
  }

  .definicao p {
    width: 100%;
  }

  .portal .cardtable {
    left: 0;
  }

  .portal .exercer-card {
    display: block;
  }

  .portal .home-button {
    border-radius: 41px;
    height: 43px;
    justify-items: center;
    width: 43px;
    padding: 0;
  }

  .portal .home-button .text-home {
    display: none;
  }

  .portal .home-button .seta-home {
    transform: scaleX(-1);
    display: block;
    height: 24px;
  }
}

@media (max-width: 375px) {
  .portal .cardtable{
  gap: 10px;
  }

  .portal .cardtable h5 {
  font-size: larger;
  }
}

@media (max-width: 280px){
  .portal .single-card h3 {
  font-size: x-large;
  }
}

</style>