<template>
  <div class="contact">
    <div class="contact--lockup">
      <div class="modal">
        <div class="modal--information">
          <p>
            <a 
              target="_blank"
              rel="noopener" 
              href="https://www.google.com/maps/place/JMJ+Sistemas+e+Consultoria/@-11.852454,-55.5356191,17z/data=!3m1!4b1!4m5!3m4!1s0x93a7802cec4c989d:0x3ca0c3b6909fc97d!8m2!3d-11.852454!4d-55.5334304">
              Rua Av. Bruno Martini, 926 - Jd. Barcelona, Sinop, Mato Grosso, Brasil, 78555-350
            </a>
          </p>
          <a href="mailto:contato@jmjsistemas.com.br">contato@jmjsistemas.com.br</a>
          <a href="tel:+556635325331">(66) 3532-5331</a>
        </div>
        <ul class="modal--options">
          <li>
            <a
              href="mailto:comercial@jmjsistemas.com"
              title="Enviar mensagem via Email"
              target="_blank"
              rel="noopener"
            >
              <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="36"
                width="36" xml:space="preserve" viewBox="0 0 1.4536875 1.4536875" y="0px" x="0px"
                version="1.1"
              >
                <path
                  id="emailSvg"
                  d="m 0.17219989,0.44392536 c -0.0587649,0 -0.10661027,0.047807 -0.10661027,0.10661489 V 1.2775103 c 0,0.058807 0.0478454,0.1066103 0.10661027,0.1066103 H 1.2814876 c 0.058765,0 0.1066103,-0.047799 0.1066103,-0.1066103 V 0.55054025 c 0,-0.0588069 -0.047845,-0.10661489 -0.1066103,-0.10661489 z m 0,0.0426405 H 1.2814876 c 0.011037,0 0.021429,0.00282 0.030502,0.007733 l -0.53490793,0.4664172 c -0.0272075,0.0237539 -0.0732658,0.0237539 -0.10051594,0 L 0.1417936,0.49424788 c 0.00905,-0.004913 0.0194074,-0.007733 0.030406,-0.007733 z M 0.11317376,0.52590575 0.5468544,0.90422806 0.3026527,1.1332395 c -0.008572,0.00801 -0.008998,0.021534 -9.3797e-4,0.030104 0.004222,0.00446 0.009897,0.00673 0.0155687,0.00673 0.005246,0 0.010486,-0.00191 0.01458,-0.00582 L 0.57733148,0.93405639 c 6.1037e-4,-5.7133e-4 0.001167,-0.001183 0.001695,-0.00182 l 0.0695197,0.0606447 c 0.0215784,0.0188048 0.049939,0.0282299 0.0782979,0.0282299 0.028359,0 0.0566768,-0.00943 0.078298,-0.0282753 l 0.0695399,-0.0606356 c 5.3422e-4,6.3319e-4 0.001101,0.001274 0.00172,0.00182 L 1.1217793,1.1642168 c 0.00409,0.00391 0.00934,0.00582 0.014585,0.00582 0.00571,0 0.011342,-0.00227 0.015564,-0.00673 0.00806,-0.00855 0.00763,-0.022089 -9.37e-4,-0.030104 L 0.90685327,0.90420804 1.3405642,0.5260322 c 0.00315,0.007551 0.00489,0.0158298 0.00489,0.024509 v 0.72697 c 0,0.035271 -0.028698,0.063965 -0.063965,0.063965 H 0.17219989 c -0.0352675,0 -0.0639651,-0.028694 -0.0639651,-0.063965 V 0.55054116 c 0,-0.008734 0.001761,-0.0170489 0.004939,-0.0246354 z"
                  style="fill:#ffffff;stroke:#ffffff;stroke-width:0.08265677;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
                />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://wa.me/556635325331"
              title="Enviar mensagem via Whatsapp"
              target="_blank"
              rel="noopener"
            >
              <img src="assets/img/icones/whatsapp.png" alt="Whatsapp"/>
            </a>
          </li>

          <li>
            <a
              href="https://www.instagram.com/jmjsistemas/"
              title="Rede Social: Instagram"
              target="_blank"
              rel="noopener"
            >
              <img src="assets/img/icones/instagram.png" alt="Instagram"/>
            </a>
          </li>

          <li>
            <a
              href="https://www.linkedin.com/company/jmj-sistemas"
              title="Rede Social: LinkedIn"
              target="_blank"
              rel="noopener"
              >
                <img src="assets/img/icones/linkedin.png" alt="LinkedIn"/>
            </a>
          </li>
        </ul>
        <button type="button" class="contact-button" @click="showContactForm">Enviar Mensagem</button>      
      </div>
    </div>

    <AlertSuccess
      v-if="showAlert"
      @alertCLose="finish"
      :contact-only="contactOnly"
    />

    <ModalContact
      v-if="showForm"
      :show="showForm"
      :contact-only="contactOnly"
      :origem="origem"
      @modalClosed="afterCloseModal"
      @modalSuccess="showAlertMessage"
    />
  </div>
</template>

<script>
import AlertSuccess from "../AlertSuccess.vue";
import ModalContact from "../ModalContact.vue";
export default {
  name: "Contato",
  components: { AlertSuccess, ModalContact },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    origem: {
      type: String,
      default: 'Não informado'
    },
    contactOnly: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return { showForm: false, showAlert: false };
  },

  created: function () {
    this.$on("closeModalEvent", () => {
      this.showForm = false;
    });
  },
  methods: {
    showContactForm: function () {
      this.showForm = true;
    },
    afterCloseModal: function () {
      this.showForm = false;
    },
    showAlertMessage: function () {
      this.showForm = false;
      this.showAlert = true;
    },
    finish: function () {
      this.showAlert = false;
      
    },
  },
  mounted () {
    if (!this.showMenu) {
      document.querySelector('.header--nav-toggle').style.display='none'
      document.querySelector('.l-side-nav').style.display='none'
    } else {
      document.querySelector('.header--nav-toggle').style.display=''
      document.querySelector('.l-side-nav').style.display=''
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.contact {
  /* border: 1px solid white; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* background: url("/assets/img/ajuste foto-04.svg") center center no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */

  background-image:url("/assets/img/ajuste-foto-04.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  /* background-image: url("/assets/img/ajuste foto-04.svg");
  background-repeat: no-repeat; */
}

.contact--lockup {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 900px;
  max-width: 75%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: center;
  -ms-flex-pack: end;
  justify-content: center;
  margin: 0 auto;
}

.contact button.contact-button {
  background: var(--cor-preto);
  color: var(--cor-primaria-60);
  border: 1px solid var(--cor-primaria-60);
  transition: all 300ms ease;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 1px 15px;
}
.contact button.contact-button:hover {
  font-weight: 700;
  background: var(--cor-primaria-60);
  border-color: var(--cor-primaria-60);
  color: var(--cor-preto);
}

.contact--lockup .modal {
  padding: 5px;
  text-align: center;
  background-color: rgb(0, 0, 0, 0.60);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.75);
  border-radius: 15px;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.contact--lockup .modal--information p,
.contact--lockup .modal--information a {
  display: block;
  margin: 14px 0;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.contact--lockup .modal--information p {
  margin-top: 0;
}

.contact--lockup .modal--options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact--lockup .modal--options > li {
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  /*     width: 130px; */
  margin: 0 auto 25px auto;
}

.contact--lockup .modal--options > li:hover {
  opacity: 0.9;
}

/* .contact--lockup .modal--options li:nth-child(1) {
    background-color: #a4c2db;
}

.contact--lockup .modal--options li:nth-child(2) {
    background-color: #006097
}

.contact--lockup .modal--options li:nth-child(3) {
    margin-bottom: 0;
    background-color: var(--cor-primaria);
}
 */

.contact--lockup .modal--options a {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.contact--lockup .modal--options li:nth-child(1) a {
  color: #080808;
}

.contact-open-form {
  cursor: pointer;
  position: relative;
  background-color: black;
}

.contact-open-form:hover {
  background-color: transparent;
}

.contact-open-form:hover:before {
  left: 0;
  right: 0;
}

.contact-open-form:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: -5px;
  background: var(--cor-primaria);
  height: 4px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  z-index: 1;
}


@media all and (max-width: 769px) {
  .contact {
    background-size: 1250px;
    background-position: -160px -150px, center !important;
    /* background-position: bottom !important; */
  }

  .contact--lockup .modal {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}


@media (max-width: 1180px) {
  .contact--lockup {
    max-width: 90%;
  }
}

@media (max-width: 769px) {
  .contact--lockup {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
.contact--lockup {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
</style>
