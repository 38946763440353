<template>
  <div class="intro">
    <div class="intro--banner">
      <div
        class="lottie-animation"
        id="animation-background"
        data-src="assets/lottie/home/data.json"
      ></div>

      <div class="row">
        <div class="col-9 intro-text">
          <h3>Uma solução</h3>
          <h1>JMJ <small>Sistemas e Consultoria</small></h1>
          <h2>para cada etapa do processo de gestão de Operadoras de Saúde!</h2>
        </div>
        <div class="col-3">
          <div
            class="lottie-animation"
            id="animation-logo"
            data-src="assets/lottie/logo/data.json"
          ></div>
        </div>
      </div>
    </div>
    <div class="intro--options">
      <div class="col-5">
        <p>
          A JMJ trabalha focada em ajudar as Operadoras de Saúde a resolverem os
          seus problemas, desde os simples aos mais complexos.
        </p>
      </div>
      <div class="col-5">
        <p>
          Soluções feitas e adaptadas conforme a necessidade da Operadora,
          visando facilidade e eficiência no processo operacional, auxiliando o
          processo de gestão.
        </p>
      </div>
      <div class="col-5">
        <p>
          Com criatividade, competência e tecnologia nós vamos transformar a sua
          dificuldade em um case de sucesso!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    msg: String,
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.intro {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 900px;
  max-width: 75%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

.intro--banner {
  position: relative;
  height: 475px;
  margin-top: 90px;
}
.intro--banner::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: -15px;
  right: 0;
  height: 2px;
  background-color: #282828;
}

.intro--banner::after {
  content: "";
  position: absolute;
  bottom: 18px;
  left: 0;
  width: 98%;
  height: 4px;
  background-color: var(--cor-primaria);
}

.intro--banner h1 {
  position: relative;
  font-size: 80px;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
}

.intro--banner h1 small {
  font-size: 42px;
}
.intro--banner h1,
.intro--banner h2,
.intro--banner h3 {
  padding: 0px 5px;
  margin: 0px 5px;
}

.intro--banner h2 {
  font-size: 21px;
}
.intro--banner button {
  position: relative;
  padding: 5px 17px 5px 12px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
}

.intro--banner button .btn-background {
  position: absolute;
  top: 0;
  left: 23px;
  right: 0;
  height: 100%;
  background-color: var(--cor-primaria);
  z-index: -1;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

.intro--banner button:hover .btn-background {
  left: 0;
}

.intro--banner button:focus {
  outline: none;
}

.intro--banner button svg {
  position: relative;
  left: 5px;
  width: 15px;
  fill: #fff;
}

.intro--banner img {
  position: absolute;
  bottom: 21px;
  right: -12px;
}

.intro--options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.intro--options > a {
  max-width: 250px;
  text-decoration: none;
  color: #c3c3c3;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.intro--options > a:hover {
  color: #fff;
}

.intro--options h3 {
  font-size: 16px;
  text-transform: uppercase;
}

.intro--options p {
  margin-bottom: 0;
  padding: 0px 5px;
}

@media (max-width: 1180px) {
  .intro {
    max-width: 100%;
  }
}

@media (max-width: 900px) {
  .intro--banner {
    height: 380px;
  }

  .intro--banner h1 {
    font-size: 50px;
  }

  .intro--banner img {
    width: 430px;
  }

  .intro--options > a {
    margin-right: 30px;
  }

  .intro--options > a:last-child {
    margin-right: 0;
  }
}

@media (max-width: 769px) {
  .intro--banner h1 {
    font-size: 44px;
  }

  .intro--banner img {
    width: 330px;
  }

  .intro--options {
    display: flex;
    flex-direction: column;
  }

  .intro--options > a {
    display: block;
    max-width: 100%;
    margin: 0 0 30px 0;
  }

  .intro--options > a:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .lottie-animation#animation-background {
    display: none;
  }
  .lottie-animation#animation-logo {
    top: -50px;
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .intro--banner {
    height: 160px;
    margin-top: 0px;
    padding-top: 80px;
  }

  .intro--banner h1 {
    font-size: 55px;
  }

  .intro--banner img {
    display: none;
  }

  .intro--banner h1 {
    font-size: 36px;
  }
  .intro--banner h2 {
    font-size: 14px;
  }
  .intro--banner::after,
  .intro--banner::before {
    bottom: -10px;
  }
}

.lottie-animation#animation-background {
  position: fixed;
  top: -77px;
  width: 70%;
  left: 18%;
}
@media (max-width: 600px) and (max-height: 750px) {
  .intro--banner {
    height: auto;
  }

  .intro--banner h1 {
    margin-top: 0;
  }

  .intro--options {
    /*   display: none */
    font-size: 12px;
  }
}
@media (min-width: 991px) {
  .intro-text {
    padding-top: 100px;
  }
}
</style>
