<template>
  <div>
    <div class="section--banner">
      <h3>Aqueles que fazem a diferença</h3>
      <h2>Nossos Clientes</h2>
      <div>
        <div class="row main-row">
          <div class="col-9 fix-left-space">
            <div class="row clients-list">
              <div
                class="col-3 clearfix fix-left-space"
                :key="index"
                v-for="(cliente, index) in clientes"
              >
                <img
                  :src="cliente.logo"
                  :alt="cliente.nome"
                  class="client-image"
                />
                <h5>{{ cliente.nome }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="bg client-bg-graphics">
          <div
            class="lottie-animation"
            data-src="assets/lottie/shaking-hands/data.json"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clients } from "@/services/firebase.js";
export default {
  name: "Clientes",
  data() {
    return {
      selectedIndex: 0,
      modified: 0,
      intercepted: false,
      clientes: [],
    };
  },
  mounted() {
    clients.list().then((res) => {
      this.clientes = res;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-row {
  padding-top: 20px;
}

.row.clients-list > .col-3 {
  min-height: 6rem;
}
.client-image {
  max-width: 100%;
  transition: all 500ms ease;
  height: auto;
  max-height: 50px;
}

.client-image + h5 {
  margin: 0;
  padding: 0;
}

@media (max-width: 769px) {
  .client-image + h5 {
    display: none;
  }
  #clientes > div:first-child {
    max-width: 100% !important;
    width: 100%;
  }
  .row.clients-list > .col-3 {
    max-width: 95px;
    min-height: auto;
    margin: 3px;
  }
  .client-image {
    min-height: 46px;
  }
  .row.clients-list > .col-3 {
    min-height: 6rem;
  }
}

@media (max-width: 640px) {
  .client-image {
    min-height: 20px;
    max-height: 25px;
  }
  .client-image + h5 {
    display: block;
  }
  .row.clients-list > .col-3 {
    min-height: 70px;
  }
}

.fix-left-space {
  padding-left: 0 !important;
  margin-left: 0 !important;
}
</style>
