<template>
  <div class="slide-container" :class="{ active: slide }">
    <div class="slider-modal" :class="{ active: slide }">
      <button type="button" class="close-slider" v-on:click="closeMe">
        <span></span>
      </button>
      <div class="slider-modal-body">
        <ol class="vagas-list">
          <li
            :class="{ visible: current == index }"
            :key="index"
            v-for="(vaga, index) in vagas"
          >
            <h1>{{ vaga.nome }}</h1>
            <p>{{ vaga.descricao }}</p>
            <div class="vaga-tags">
              <ul>
                <li
                  class="vaga-tag"
                  :key="index"
                  v-for="(tag, index) in vaga.tags"
                >
                  {{ tag }}
                </li>
              </ul>
            </div>
            <a rel="noopener" :href="vaga.url" v-show="vaga.url"> Eu quero! </a>
          </li>
        </ol>
        <ol class="paginator" v-if="vagas.length > 1">
          <li
            :class="{ selected: current == index }"
            :key="index"
            @click="current = index"
            v-for="(vaga, index) in vagas"
          ></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { positions } from "@/services/firebase.js";
export default {
  props: ["slide"],
  data: function () {
    return {
      current: 0,
      vagas: [
        {
          name: "Banco de Talentos",
          description:
            "Estamos sempre de olhos, mostre pra gente o que você sabe ;)",
          tags: ["dba", "dev", "análise", "design", "marketing"],
          url: "http://www.jmjsismtas.com.br",
        },
      ],
    };
  },
  mounted() {
    positions.list().then((res) => {
      this.vagas = res;
      this.vagas.forEach((each) => {
        each.tags = (each.tags || "").split(";");
      });
    });
  },
  methods: {
    closeMe: function () {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.slide-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.slider-modal {
  bottom: 30px;
  position: absolute;
  width: 100%;
  min-width: 500px;
  max-width: 60%;
  right: -10000px;
  margin: 0;
  padding: 0;
  min-height: 250px;
  color: #f3f3f3 !important;
  transition: all 800ms ease-in-out, all 800ms ease-in-out;
}

.slide-container.active {
  z-index: 9;
}
.slider-modal.active {
  right: 0;
}
.slider-modal-body {
  width: 100%;
  min-height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: var(--cor-preto);
  border: 1px solid var(--cor-secundaria);
  border-left: 0;
  border-right: 0;
}

.slider-modal ol {
  min-height: 250px;
  position: relative;
}
.slider-modal ol,
.slider-modal ul {
  margin: 0;
  padding: 0;
}
.slider-modal ol.vagas-list > li {
  display: none;
  margin: 0;
  position: relative;
  padding: 28px 0;
}

.slider-modal ol > li.visible {
  display: block !important;
}

.slider-modal .vaga-tag {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--cor-secundaria-50);
  border-radius: 16px;
  margin: 0px 5px 5px;
  min-width: 38px;
  text-align: center;
  color: var(--cor-preto) !important;
  text-transform: uppercase;
}

.slider-modal h1 {
  --slider-font-size: 48px;
  font-size: var(--slider-font-size) !important;
  line-height: 55px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--cor-secundaria);
  /*
     text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;*/
}
.slider-modal li a {
  background: var(--cor-secundaria-70) !important;
  color: white !important;
  border-radius: 14px !important;
  padding: 6px 40px;
  font-weight: bold;
  text-decoration: none;
  margin: 5px 0px;
  display: inline-block;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
}
.slider-modal li a:hover {
  background: var(--cor-secundaria-50) !important;
  box-shadow: 0px 0px 6px var(--cor-secundaria);
}
.slider-modal:before,
.close-slider {
  display: block;
  content: "";
  position: absolute;
}

.slider-modal:before {
  height: 99.5%;
  width: 300px;
  background: var(--cor-preto);
  top: 0;
  left: -130px;
  border-radius: 200px 0 0 200px;
  border: 1px solid var(--cor-secundaria);
  box-shadow: 0 0 16px 14px black;
}

.close-slider {
  --size: 20px;
  transform: rotate(45deg);
  width: var(--size);
  height: var(--size);
  border: 5px solid var(--cor-secundaria);
  top: calc((100% - var(--size)) / 2);
  left: -70px;
  border-left: 0px;
  border-bottom: 0px;
  transition: all 300ms ease-in;
  cursor: pointer;
  pointer-events: all;
  outline: none;
  background: transparent;
  appearance: none;
}

.close-slider:hover {
  border-color: var(--cor-secundaria-80);
  left: -60px;
}

@media (max-width: 900px) {
  .slider-modal.active {
    right: 0;
    max-width: 60%;
    width: 60%;
    min-width: auto;
  }

  .vaga-tag {
    display: none;
  }
  .slider-modal:before {
    left: -149px;
  }
}
ol.paginator li {
  cursor: pointer;
  pointer-events: all;
  display: inline-block;
  color: transparent;
  background: white;
  width: 3px;
  height: 3px;
  margin: 3px;
  border-radius: 20px;
  padding: 3px;
}
ol.paginator li.selected {
  background: var(--cor-secundaria-50);
}
ol.paginator {
  position: absolute;
  bottom: 10px;
}

@media (max-width: 768px) {
  ol.paginator {
    bottom: 45px;
    left: -30px;
  }

  ol.paginator li {
    display: block;

    margin: 10px 0;
  }
  .slider-modal ol.vagas-list > li {
    padding: 13px 0;
  }

  .slider-modal h1 {
    --slider-font-size: 30px;
  }
}
</style>
