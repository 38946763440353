<template>
  <div class="about">
    <div class="about--banner">
      <h2>Quem somos?</h2>
      <p> A JMJ nasceu para suprir as necessidades específicas da área tecnológica das operadoras de saúde.
      </p>
      <p> Criada em novembro de 2014 em Sinop/MT, a partir da união de duas experiências importantes: mais
        de 8 anos em desenvolvimento e 6 anos em consultoria. Com conhecimento especializado nas regras de
        negócio e estrutura de dados necessários para os
        processos das operadoras de saúde, a empresa se destaca pelo fornecimento de soluções criadas com
        foco no
        cliente, objetivando atender 100% de suas necessidades.</p>


        <div class="lottie-animation" data-src="assets/lottie/quem-somos/data.json"></div>
     </div>

  

  </div>
</template>

<script>
export default {
  name: 'Sobre',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.about{
 position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 900px;
  max-width: 75%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

.about--banner {

  position: relative;
  height: 475px;
}

.about--banner p {
  max-width: 80%;
}

.about--banner h3 {
  margin: 0;
}

.about--banner .lottie-animation {
  margin-top: 50px;
}

.about--banner h2 {
 position: relative;
  font-size: 48px;
  line-height: 1;
  z-index: 1;
  margin: 0;
}

.about--banner h1 {
 font-size: 68px !important;
  margin: 0;
}
.about--banner a:not(.slider-pagination) {
  padding: 5px 17px 5px 0;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  background-color: transparent;
}

.about--banner a:hover svg {
  left: 10px;
}

.about--banner a svg {
  position: relative;
  left: 5px;
  width: 15px;
  fill: #fff;
  -webkit-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

.about--banner img.bg {
  position: absolute;
  bottom: 0px;
  right: -12px;
  width: 500px;
  z-index: -1;
}

.about--banner img.bg.lamp {
  width: 400px;
}

.about--options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
}

.about--options > a {
  position: relative;
  width: 150px;
  height: 75px;
  text-decoration: none;
  color: #fff;
  text-align: center;
}

/* .about--options>a:nth-child(1) {
    background-image: url("../img/fundos/opme.jpg")
}

.about--options>a:nth-child(2) {
    background-image: url("../img/fundos/audit.jpg")
}

.about--options>a:nth-child(3) {
    background-image: url("../img/fundos/crm.jpg")
}
.about--options>a:nth-child(4) {
    background-image: url("../img/fundos/compare.jpg")
}

.about--options>a:nth-child(5) {
    background-image: url("../img/fundos/viver_bem.jpg")
}
 */

.about--options > a:hover h5 {
  bottom: -40px;
}

.about--options h5 {
  position: absolute;
  bottom: -48px;
  left: 10px;
  text-transform: uppercase;
  -webkit-transition: bottom 0.2s ease-in-out, left 0.2s ease-in-out;
  transition: bottom 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 150px;
  text-align: center;
}

.about--display {
  text-align: center;
  padding: 50px 0px;
  width: 400px;
  position: absolute;
  height: 500px;
  background: rgba(0, 0, 0, 0.9);
  right: 0;
  top: 150px;
  transform: perspective(10000px) scale(0.001);
  transition: all 0.5s ease;
}

.about--display span {
  margin: 0px 20px;
}

.about--display.active {
  transform: scale(1);
}

@media (max-height: 768px) {
  .about--banner img.bg.lamp {
    width: 350px;
  }
}

@media (max-width: 769px) {


  .about,
  li.section > div:first-child {
    justify-content: end;
  }

  .about--banner h2 {
    font-size: 36px !important;
  }
  .about--banner p {
    max-width: 100%;
  }


  
}



@media (max-width: 1180px) {
  .about {
    max-width: 100%;
  }
}

@media (max-width: 769px) {
  .about--banner {
    height: 305px;
  }

  .about--banner h2 {
    margin-top: 10px;
    font-size: 44px;
  }

  .about--banner h2::before {
    top: 28px;
    left: 168px;
  }

  .about--banner h2::after {
    top: 163px;
    left: 163px;
  }

  .about--banner img.bg {
    width: 315px;
  }
}



@media (max-width: 600px) {
  .about--banner {
    height: 80%;
  }

  .about--banner h2 {
    margin-top: 0;
    font-size: 55px;
  }

  .about--banner h2::before {
    top: 43px;
    left: 214px;
  }

  .about--banner h2::after {
    top: 205px;
    left: 205px;
  }

  .about--banner img.bg {
    display: none;
  }

  .about--options img {
    display: none;
  }

  .about--options a {
    text-align: left;
  }

  .about--options h5 {
    display: block;
    width: 300px;
    position: relative;
    height: 10px;
    bottom: 0px;
    transition: margin 0.2s ease-in-out, margin 0.2s ease-in-out;
  }

  .about--options > a:hover h5 {
    bottom: inherit;
    margin-left: 25px;
  }

  .about--options {
    display: block;
  }
}


@media (max-width: 1024px) {
  .about--banner img.bg {
    opacity: 0.2;
    bottom: -50px;
  }

}


@media (max-width: 580px) {


  .about--banner p {
    font-size: 12px;
  }
}




</style>
