<template>
  <div class="inner-section-for-work">
    <h2>Soluções</h2>
    <div class="work">
      <div class="work--lockup">
        <ul class="slider">
          <li
            :key="index"
            v-for="(sistema, index) in sistemas"
            class="slider--item"
            v-bind:class="{
              'slider--item-left': index == 0,
              'slider--item-right': index == 2,
              'slider--item-center': index == 1,
            }"
          >
            <div class="slider--item-image">
              <div class="slide--item-overlay"></div>
              <img
                v-bind:src="sistema.logo"
                :alt="sistema.nome"
                :title="sistema.nome"
                :download="sistema.nome"
              />
            </div>
            <p class="slider--item-title">
              <strong :style="{ color: sistema.colorJMJ }">JMJ</strong>
              <span :style="{ color: sistema.color }">
                {{ sistema.nome }}
              </span>
            </p>
            <p class="slider--item-description" v-text="sistema.descricao"></p>
          </li>
        </ul>
        <div class="slider--prev">
          <svg
            version="1.1"
            id="prev"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 150 118"
            style="enable-background: new 0 0 150 118"
            xml:space="preserve"
          >
            <g
              transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)"
            >
              <path
                d="M561,1169C525,1155,10,640,3,612c-3-13,1-36,8-52c8-15,134-145,281-289C527,41,562,10,590,10c22,0,41,9,61,29
                                    c55,55,49,64-163,278L296,510h575c564,0,576,0,597,20c46,43,37,109-18,137c-19,10-159,13-590,13l-565,1l182,180
                                    c101,99,187,188,193,199c16,30,12,57-12,84C631,1174,595,1183,561,1169z"
              />
            </g>
          </svg>
        </div>
        <div class="slider--next">
          <svg
            version="1.1"
            id="next"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 150 118"
            style="enable-background: new 0 0 150 118"
            xml:space="preserve"
          >
            <g
              transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)"
            >
              <path
                d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z"
              />
            </g>
          </svg>
        </div>
      </div>
      <FabSolutions @clicked="fabClicked" />
      <SendMessage :show="showSendMessage" />
    </div>
  </div>
</template>

<script>
import FabSolutions from "../FabSolutions.vue";
import SendMessage from "../SendMessage.vue";
import { products } from "@/services/firebase.js";

export default {
  name: "Sistemas",
  components: { SendMessage, FabSolutions },
  data: function () {
    return {
      sistemas: [],
      showSendMessage: false,
    };
  },
  mounted() {
    products.list().then((res) => {
      this.sistemas = res;
    });
  },
  methods: {
    fabClicked() {
      this.showSendMessage = !this.showSendMessage;
    },
  },
};
</script>service

<style scoped>
.inner-section-for-work {
 /* margin-top: -80px !important;*/
  display: block !important;
}

#solucoes h2 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 12px;
  /*padding-bottom: 5px;*/
  font-size: 48px;
}
.work--lockup {
  position: relative;
}

.work--lockup .slider {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.work--lockup .slider--item {
  position: absolute;
  display: none;
  text-align: center;
}

.work--lockup .slider--item a {
  text-decoration: none;
  color: #858585;
}

.work--lockup .slider--item-title {
  margin-top: 10px;
  font-weight: 700;
  display: none;
}

.work--lockup .slider--item-description {
  display: none;
  /*max-width: 250px;*/
  min-height: 250px;
  margin: 0 auto;
}

.work--lockup .slider--item-image {
  --size: 15em;
  width: var(--size);
  height: var(--size);
  margin: 0 auto;
}

.work--lockup .slider--item-image img {
  width: 60%;
  z-index: 99;
  position: relative;
}

.work--lockup .slider--item-left {
  top: 50%;
  left: -40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}

.work--lockup .slider--item-right {
  top: 50%;
  right: -40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}

.work--lockup .slider--item-center {
  position: relative;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
}

.work--lockup .slider--item-center a {
  color: #fff;
}

.work--lockup .slider--item-center .slider--item-title {
  margin-top: 0;
  font-size: 40px;
  display: block;
  margin-bottom: 0;
}

.work--lockup .slider--item-center .slider--item-description {
  display: block;
  font-size: 18px;
}

.work--lockup .slider--next,
.work--lockup .slider--prev {
  position: absolute;
  top: 160px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
}

.work--lockup .slider--next svg,
.work--lockup .slider--prev svg {
  width: 20px;
  fill: var(--cor-secundaria);
}

.work--lockup .slider--next {
  right: 0;
}

.work--lockup .slider--prev {
  left: 0;
}

.work--lockup .slider--item-center img {
  animation: animateLogo 0.7s 1;
  top: 5%;
  width: 80% !important;
  /* IE 10+, Fx 29+ */
}
.section--is-active .work--lockup .slider--item-center .slider--item-title,
.section--is-active
  .work--lockup
  .slider--item-center
  .slider--item-description {
  animation: animateText 1.2s 1;
  opacity: 1;
}
.slider--item-image {
  position: relative !important;
  margin-bottom: 55px !important;
}

.slider--item-left .slider--item-image img,
.slider--item-right .slider--item-image img {
  border-radius: 100%;
  background: rgba(5, 108, 242, 0.3);
  padding: 10px;
  border: 3px solid rgba(5, 108, 242, 0.3);
}

@media (max-width: 768px) {
  .work--lockup .slider--item-center .slider--item-title {
    font-size: 36px;
  }
  .work--lockup li.slider--item-center .slider--item-image::before {
    --size: 250px;
    top: -20%;
    left: -36%;
  }
  .work--lockup .slider--item-center .slider--item-image {
    width: 170px;
    height: 170px;
  }
}

@media (max-width: 900px) {
  .work--lockup .slider--item-image {
    --size: 120px;
  }

  .work--lockup .slider--item-center .slider--item-image {
    --size: 240px;
  }

  .work--lockup .slider--next,
  .work--lockup .slider--prev {
    top: 130px;
  }
}

@media (max-width: 769px) {
  .inner-section-for-work {
    margin-top: 0px !important;
  }
  .work--lockup .slider {
    width: 75%;
  }

  .work--lockup .slider--item-image {
    width: 90px;
    height: 90px;
  }

  .work--lockup .slider--item-center .slider--item-image {
    --size: 190px;
  }

  #solucoes h2 {
    font-size: 2em;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
    padding-bottom: 20px;
  }

  .work--lockup .slider--next,
  .work--lockup .slider--prev {
    top: 60px;
  }
  .work--lockup .slider--prev {
    left: -30px;
  }
  .work--lockup .slider--next {
    right: -30px;
  }
}

@media (max-width: 480px) {
  #solucoes h2 {
    font-size: 100%;
  }
  .work--lockup .slider--item-center .slider--item-image {
    width: 160px;
    height: 160px;
    margin-bottom: 20px !important;
  }
  .work--lockup li.slider--item-center .slider--item-image::before {
    --size: 213px;
    top: -18%;
    left: -28%;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 600px) {
  .work--lockup .slider {
    width: auto;
  }

  .work--lockup .slider--item-left,
  .work--lockup .slider--item-right {
    display: none;
  }
}
@media (max-height: 768px) and (min-width: 768px) {
  .work--lockup .slider--item-center {
    top: 0px;
  }

  /* 
    .work--lockup .slider--item-center img {
        height: 50%;
        width: 50%
    } */

  .work--lockup .slider--item-center .slider--item-image {
    --size: 200px;
  }

  .work--lockup .slider--item-description {
    max-width: 400px;
  }
}

@media (max-width: 768px) and (max-height: 768px) {
  .work--lockup .slider--item-center .slider--item-description {
    font-size: 12px;
  }

  .test-clients h5 {
    margin: 0px;
  }
}

@media (max-width: 568px) {
  .work--lockup .slider--item-center {
    top: 0px;
  }

  .work h2 {
    margin: 0 0 2px 0;
  }
}

.slider--item-center .slide--item-overlay {
  position: absolute;
  top: -48px;
  left: -76px;
  height: calc(100% + 100px);
  width: calc(100% + 100px);
  background: url(/assets/img/sistemas/background.webp);
  background-size: cover;
}
</style>
