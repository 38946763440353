<template>
  <div class="alert" id="alert-solucoes">
    <button class="close" @click="closeMe">
      <svg
        width="15"
        height="20"
        viewBox="0 0 15 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 1.5L1.5 5L6.5 10L1.5 15L5 18.5L13.5 10L5 1.5Z"
          stroke="#05F2F2"
        />
      </svg>
    </button>
    <div class="alert-body">
      <h3>Quer <strong>saber mais</strong> sobre nossos produtos?</h3>
      <button type="button" @click="openModal($event)">ENVIAR MENSAGEM</button>
    </div>

    <ModalContact
      v-if="showModal"
      :show="showModal"
      @modalClosed="afterCloseModal"
      @modalSuccess="showAlertMessage"
    >
    </ModalContact>
    <AlertSuccess v-if="showAlert" @alertCLose="finish" />
  </div>
</template>

<script>
import ModalContact from "./ModalContact.vue";
import AlertSuccess from "./AlertSuccess.vue";
export default {
  name: "SendMessage",
  props: ["show"],
  components: { ModalContact, AlertSuccess },
  data: function () {
    return {
      showModal: false,
      showAlert: false,
      alert: null,
    };
  },
  watch: {
    show(val) {
      if (val) this.showMe();
      else this.closeMe();
    },
  },
  mounted() {
    this.alert = document.querySelector("#alert-solucoes");
    this.openModal.bind(this);
    this.closeMe.bind(this);
    this.showMe.bind(this);
    this.afterCloseModal.bind(this);
  },
  methods: {
    openModal: function () {
      this.showModal = true;
    },
    showMe: function () {
      this.alert.classList.toggle("show");
    },
    closeMe: function () {
      this.alert.classList.toggle("start-close-animation");
      setTimeout(() => {
        this.alert.classList.toggle("show");
        this.alert.classList.toggle("start-close-animation");
      }, 1500);
    },
    afterCloseModal: function () {
      this.showModal = false;
    },
    showAlertMessage: function () {
      this.showModal = false;
      this.showAlert = true;
    },
    finish: function () {
      this.showAlert = false;
      this.closeMe();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#alert-solucoes.alert {
  position: absolute;
  width: 378px;
  height: 145px;
  bottom: 112px;
  right: -3000px;
  opacity: 0;
  transition: right 350ms ease, opacity 900ms;
}

#alert-solucoes.alert.show {
  right: 0px;
  position: fixed;
  opacity: 1;
}

#alert-solucoes.alert.start-close-animation {
  right: -500px;
  opacity: 0;
  transition: right 1000ms ease, opacity 400ms;
}

#alert-solucoes.alert::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 174px;
  left: -1px;
  top: -10%;
  z-index: -1;

  border: 1px solid #05f2f2;
  box-sizing: border-box;
  border-radius: 40px;
}

#alert-solucoes .alert-body {
  height: 100%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  background: #000000;
  border: 1px solid #05f2f2;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(5, 242, 242, 0.08);
  z-index: 3;
}

#alert-solucoes .alert-body button {
  color: var(--cor-secundaria);
  border: 2px solid var(--cor-secundaria);
  padding: 4px 20px;
  background: var(--cor-preto);
}

#alert-solucoes.alert button.close {
  --size: 35px;
  display: block;
  position: absolute;
  width: var(--size);
  height: var(--size);
  left: calc((var(--size) + 10px) * -1);
  top: calc((100% - var(--size)) / 2);
  cursor: pointer;
  pointer-events: auto;
  border: 2px solid #05f2f2;
  box-sizing: border-box;
  background: var(--cor-preto);
  border-radius: 40px;
  padding: 5px;
}

#alert-solucoes.alert strong {
  color: var(--cor-secundaria);
}

@media (max-width: 768px) {
  #alert-solucoes.alert {
    top: 50%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  #alert-solucoes.alert {
    max-width: 80%;
  }
}
</style>
