<template>
  <nav class="l-side-nav">
    <ul class="side-nav">
      <li class="is-active"><span>Início</span></li>
      <li><span>Soluções</span></li>
      <li><span>Sobre</span></li>
      <li><span>Clientes</span></li>
      <li><span>Parceiros</span></li>
      <li><span>Contato</span></li>
      <li><span>Família JMJ</span></li>
      <li><span>Junte-se a nós</span></li>
      <li><span>LGPD</span></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "MenuLine",
};
</script>

<style scoped>
</style>
