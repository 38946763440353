<template>
  <div class="jmj-modal" :class="{ active: show }" @keyup.esc="closeModal">
    <div class="modal-body" :class="{ leave: leave }">
      <slot></slot>

      <div class="modal-footer">
        <button type="button" @click="closeModal">
          {{ cancelLabel || "Cancelar" }}
        </button>
        <button type="button" class="submit" @click="submitModal">
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["cancelLabel", "show", "onSubmit"],
  data: function () {
    return {
      modalActive: false,
      leave: false,
    };
  },
  mounted() {
    this.closeModal.bind(this);
    this.submitModal.bind(this);
  },
  methods: {
    submitModal() {
      this.onSubmit();
    },
    closeModal() {
      this.leave = true;
      setTimeout(() => {
        this.$emit("modalClosed");
        this.leave = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.jmj-modal > .modal-body,
.jmj-modal > .modal-body.leave {
  z-index: 99;
  display: block;
  position: relative;
  padding: 20px 50px;
  width: 500px;
  z-index: 99998;
  background: var(--cor-preto);
  border: 1px solid var(--cor-secundaria);
  margin-top: -1000px;
  transition: margin 500ms ease-in-out;
    box-shadow: 0px 0px 60px 60px #000000c4;
}

.jmj-modal > .modal-body > .modal-footer {
  margin-top: 30px;
}
.jmj-modal.active > .modal-body {
  margin-top: 0;
}

.jmj-modal > .modal-body.leave {
  margin-top: -5000px;
}
/* .jmj-modal.active::before {
    content: '';
    display: block;
    position: absolute;
    height: 300vh;
    width: 300vw;
    background: #131313bf;
    top: -50%;
    left: -50%;
    z-index: 4;
}
*/

.jmj-modal {
  /* position: fixed; */
  /* top: 0; */
  z-index: 9;
  /* margin: auto; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* left: -65px; */
  /* width: 0; */
  /* height: 0; */
}

.jmj-modal.active {
  /* width: 100vw;
  height: 100vh;
  left: 0; */
}

.jmj-modal button {
  padding: 2px 40px;
  display: inline-block;
  color: var(--cor-secundaria);
  border: 2px solid;
  font-size: 15px;
  background: var(--cor-preto);
  border-radius: 6px;
}
.jmj-modal button.submit {
  color: var(--cor-preto);
  background: var(--cor-secundaria);
  border-color: var(--cor-secundaria);
}

@media (max-width: 768px) {
  .jmj-modal.active {
    left: 0;
  }
  .jmj-modal.active > .modal-body {
    /* padding-top: 40%; */
    height: 85vh;
  }
  .jmj-form button,
  .jmj-modal button {
    margin: 0px 5px;
  }
}

.jmj-modal.no-border > .modal-body {
  border: none !important;
  border-color: none !important;
}
</style>
