<template>
  <div class="open-vagas" @click="showSlider">
    <svg
      width="62"
      height="69"
      viewBox="0 0 62 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 34.5C0 15.4462 15.4462 0 34.5 0H66V69H34.5C15.4462 69 0 53.5538 0 34.5Z"
        fill="black"
      />
      <path
        d="M0.5 34.5C0.5 15.7223 15.7223 0.5 34.5 0.5H65.5V68.5H34.5C15.7223 68.5 0.5 53.2777 0.5 34.5Z"
        stroke="#05F2F2"
        stroke-opacity="0.8"
      />
      <g filter="url(#filter0_d)">
        <path d="M44 22L29 34L44 46" stroke="#05F2F2" stroke-width="7" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="18.5621"
          y="19.2666"
          width="32.4595"
          height="39.1364"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4.83517" />
          <feGaussianBlur stdDeviation="2.41758" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Fab",
  methods: {
    showSlider: function () {
      this.$emit("fabCLicked");
    },
  },
};
</script>

<style scoped>
.open-vagas {
  position: fixed;
    bottom: 80px;
    right: 0px;
    width: 50px;
  cursor: pointer;
  pointer-events: all;
}

.open-vagas svg {
  width: 100%;
}
</style>
