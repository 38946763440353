<template>
  <div>
    <div class="section--banner">
      <h2>LGPD</h2>
      <p>
        A Lei geral de Proteção de Dados - LGPD (Lei 13.709/2018) é a Lei que dispõe sobre o tratamento de dados pessoais,
        inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado,
        com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da
        personalidade da pessoa natural (art. 1º)
      </p>
      <div>
        <router-link to="/porlgpd">
          <button type="button" class="contact-button" @click="">Clique aqui para acessar o portal de privacidade</button>
        </router-link>
      </div>
      <div class="bg">
        <img src="assets/img/LgpdPage.png"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lgpd",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg{
  right: -330px !important;
}

@media (max-width: 900px) {
  #lgpd .bg img {
    max-height: 300px !important;
    max-width: 300px !important;
    margin: auto;
  }

  #lgpd .contact-button {
    height: 56px;
    width: fit-content;
  }
}

@media (max-width: 640px) {
  #lgpd .bg img {
    max-height: 240px !important;
    max-width: 2400px !important;
  }
}

@media (max-width: 530px) {
  #lgpd .bg img {
    max-height: 300px !important;
    max-width: 300px !important;
    margin: auto;
  }

  #lgpd .contact-button {
    height: fit-content;
    width: fit-content;
  }
}

section--banner p {
  max-width: 65%;
  font-size: 18px;
  text-align: justify;
}

.section--banner h2 {
  position: relative;
  font-size: 48px;
  line-height: 1;
  z-index: 1;
  margin: 0;
}
.section--banner button.contact-button {
  color: var(--cor-primaria-60);
  border: 1px solid var(--cor-primaria-60);
  padding: 4px 20px;
  background: var(--cor-preto);
  border-radius: 6px;
  height: 56px;
  width: 447px;
  font-size: 17px;
  font-weight: 500;
  line-height: 118%;
}
.section--banner button.contact-button:hover {
  font-weight: 700;
  background: var(--cor-primaria-60);
  border-color: var(--cor-primaria-60);
  color: var(--cor-preto);
}
</style>
