<template>
  <div>
    <ul class="outer-nav">
      <li class="is-active">Início</li>
      <li>Soluções</li>
      <li>Sobre</li>
      <li>Clientes</li>
      <li>Parceiros</li>
      <li>Contato</li>
      <li>Família JMJ</li>
      <li>Junte-se a Nós</li>
      <li>LGPD</li>
    </ul>
    <div class="nav-close">
      <h4>MENU</h4>
      <button type="button" class="outer-nav--return" >
        <img src="/assets/img/icones/close.png" alt="Fechar Menu" />
      </button>
    </div>
  </div>

</template>

<script>
export default {
  name: "Menu",
  methods: {
    closeNav() {
      let nav = document.querySelector(".outer-nav.is-vis");
      nav.classList.remove("is-vis");
    },
  },
};
</script>
<style scoped>
.nav-close {
  position: absolute;
  width: 223px;
  box-sizing: border-box;
  padding: 0 30px;
  z-index: 999999;
  visibility: hidden;
  top: 0;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
}
.nav-close button {
  background: transparent;
  border: none;
}

.nav-close h4 {
  font-size: 24px;
}
.outer-nav.is-vis + .nav-close {
  visibility: visible;
}

@media (max-width: 768px) {
  .nav-close h4 {
    margin: 10px;
  }
}

.outer-nav {
  padding-top: 200px;
}
.outer-nav li {
  font-weight: 500;
  font-size: 24px;
  color: var(--cor-secundaria);
  margin-bottom: 14px;
}

</style>